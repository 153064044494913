import { Body } from "@casavo/habitat"
import Link from "next/link"
import React from "react"

import { alignCenter } from "../design-lib/styles.css"
import { handleUserEventClick } from "../utils/tracking/userEvent"

import { cta } from "./Cta.css"

export type Accent = "light" | "dark"

interface CtaProps {
  accent?: Accent
  label: string
  small?: boolean
  smallMobile?: boolean
  url: string
  userEventLabel?: string
}

export const Cta: React.FC<CtaProps> = ({
  accent = "light",
  label,
  small = false,
  smallMobile = false,
  url,
  userEventLabel,
}) => {
  return (
    <Link
      aria-label={label}
      className={cta({ accent: accent === "light", small, smallMobile })}
      href={url}
      passHref={true}
      onClick={typeof userEventLabel !== "undefined" ? () => handleUserEventClick(userEventLabel) : undefined}
    >
      <div className={alignCenter}>
        <Body noMargin strong size="l">
          {label}
        </Body>
      </div>
    </Link>
  )
}
