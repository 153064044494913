
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import Head from "next/head"
import useTranslation from "next-translate/useTranslation"

import { CustomErrorPageContent } from "@app/components/error"
// import { Layout } from "@app/shared/components/layout"

// TODO: <Layout> is commented out because there is an issue with i18n not getting the correct locale
// when the 404 page hasn't the locale in the url. This is a temporary fix until we find a solution.
const Error = () => {
  const { t } = useTranslation()

  return (
    <>
      <Head>
        <title>{t("common:error.404ErrorMessage", null, { fallback: "Oops, nobody's home" })} - Casavo</title>
      </Head>
      {/* <Layout> */}
      <CustomErrorPageContent statusCode={404} />
      {/* </Layout> */}
    </>
  )
}

export default Error


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        
// @ts-ignore
        
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          
// @ts-ignore
          props: {
// @ts-ignore
            
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/404',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  