import { H1, H3 } from "@casavo/habitat"
import useTranslation from "next-translate/useTranslation"

import { Cta } from "@app/shared/components/Cta"
import { MyImage } from "@app/shared/components/MyImage"
import { imageFitContain } from "@app/shared/design-lib/styles.css"

import { container, picture1, picture2 } from "./style.css"

export const CustomErrorPageContent = ({ statusCode }: { statusCode: number }) => {
  const { t } = useTranslation()

  return (
    <div className={container}>
      <figure className={picture1}>
        <MyImage
          alt={`Error ${statusCode}`}
          className={imageFitContain}
          height={300}
          src={`/images/error404/error-m.svg`}
          width={300}
        />
      </figure>
      <figure className={picture2}>
        <MyImage
          alt={`Error ${statusCode}`}
          className={imageFitContain}
          height={260}
          src={`/images/error404/error-d.svg`}
          width={630}
        />
      </figure>
      <H1>{t(`common:error.404ErrorMessage`, null, { fallback: "Oops, nobody's home" })}</H1>
      <H3>{t(`common:error.404ErrorSubtitle`, null, { fallback: "Did you enter the right address?" })}</H3>
      <Cta
        accent="dark"
        label={t("common:error.backHome", null, { fallback: "Return to the homepage" })}
        small={true}
        url={"/"}
      />
    </div>
  )
}
